<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Peralatan Inspeksi'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12">
              <b-form class="form" @submit.stop.prevent="onSubmit">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nama_peralatan_label"
                  label="Nama peralatan :"
                  label-for="nama_peralatan"
                >
                  <b-form-input
                    id="nama_pabrik"
                    v-model="$v.form.nama_peralatan.$model"
                    type="text"
                    placeholder="Enter Text..."
                    :state="validateState('nama_peralatan')"
                  ></b-form-input>

                  <b-form-invalid-feedback id="nama_peralatan_feedback">
                    Nama Peralatan Harus Terisi.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="model_label"
                  label="Model :"
                  label-for="model"
                >
                  <b-form-input
                    id="model"
                    v-model="form.model"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="fungsi_label"
                  label="Fungsi :"
                  label-for="fungsi"
                >
                  <vue-editor id="fungsi" v-model="form.fungsi"></vue-editor>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="tahun_dibuat_label"
                  label="Tahun Dibuat :"
                  label-for="tahun_dibuat"
                >
                  <b-form-input
                    id="tahun_dibuat"
                    v-model="form.tahun_dibuat"
                    type="number"
                    placeholder="Enter Number..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="nomor_inventaris_label"
                  label="Nomor Inventaris :"
                  label-for="nomor_inventaris"
                >
                  <b-form-input
                    id="nomor_inventaris"
                    v-model="form.nomor_inventaris"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="foto_label"
                  label="Foto :"
                  label-for="foto"
                >
                  <b-form-file
                    id="foto"
                    v-model="form.foto"
                    placeholder="Choose a photo or drop it here..."
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="manual_book_label"
                  label="Manual Book :"
                  label-for="manual_book"
                >
                  <b-form-file
                    id="manual_book"
                    v-model="form.manual_book"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="manufacture_label"
                  label="Manufacture :"
                  label-for="manufacture"
                >
                  <b-form-input
                    id="manufacture"
                    v-model="form.manufacture"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="spesifikasi_label"
                  label="Spesifikasi :"
                  label-for="spesifikasi"
                >
                  <vue-editor id="spesifikasi" v-model="form.spesifikasi"></vue-editor>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="kategori_label"
                  label="Kategori :"
                  label-for="kategori"
                >
                  <b-form-select
                    id="kategori"
                    v-model="form.kategori"
                    :options="optionsKategori"
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="tanggal_pembelian_label"
                  label="Tanggal Pembelian :"
                  label-for="tanggal_pembelian"
                >
                  <b-form-input
                    id="tanggal_pembelian"
                    v-model="form.tanggal_pembelian"
                    type="date"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="link_label"
                  label="Link :"
                  label-for="link"
                >
                  <b-form-input
                    id="link"
                    v-model="form.link"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="instruksi_kerja_label"
                  label="Instruksi Kerja :"
                  label-for="instruksi_kerja"
                >
                  <b-form-input
                    id="instruksi_kerja"
                    v-model="form.instruksi_kerja"
                    type="text"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  id="kuantity_peralatan_label"
                  label="Kuantitas Peralatan :"
                  label-for="kuantity_peralatan"
                >
                  <b-form-input
                    id="kuantity_peralatan"
                    v-model="form.kuantity_peralatan"
                    type="number"
                    placeholder="Enter Text..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm
                  content-cols-lg="8"
                  label="Status Investasi"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    id="IsInvestasi"
                    v-model="form.IsInvestasi"
                    :aria-describedby="ariaDescribedby"
                    name="IsInvestasi"
                  >
                    <b-form-radio :value="true">Investasi</b-form-radio>
                    <b-form-radio :value="false">Non Investasi</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  @click="$router.push('/peralataninspeksi')"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
                <b-button
                  v-if="form.status == 2"
                  class="ml-3"
                  type="button"
                  variant="outline-primary"
                  v-on:click="formPinjam = !formPinjam"
                  :disabled="loading"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-primary">
                    <i class="flaticon-share text-primary"></i>
                  </span>
                  Kembalikan
                </b-button>
              </b-form>
            </b-col>
          </b-row>
          <b-row v-if="formPinjam">
            <b-col md="6" offset-md="3" class="text-center">
              <b-form class="form" @submit.stop.prevent="onSubmitPeminjaman">
                <b-form-group
                  label-cols-sm="6"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="6"
                  id="tgl_pinjam_label"
                  label="Tanggal Kembali :"
                  label-for="tgl_pinjam"
                >
                  <b-form-input
                    id="tgl_pinjam"
                    v-model="pinjam.tgl_pinjam"
                    type="date"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="6"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="6"
                  id="durasi_label"
                  label="Durasi (Jam):"
                  label-for="durasi"
                >
                  <b-form-input
                    id="durasi"
                    v-model="pinjam.durasi"
                    type="number"
                    placeholder="Enter Number..."
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  label-cols-sm="6"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="8"
                  id="penggunaan_label"
                  label="Penggunaan :"
                  label-for="penggunaan"
                >
                  <vue-editor id="penggunaan" v-model="pinjam.penggunaan"></vue-editor>
                </b-form-group>
                <b-form-group
                  label-cols-sm="6"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="8"
                  id="penggunaan_label"
                  label="Kondisi :"
                  label-for="penggunaan"
                >
                  <b-select :options="kondisi" v-model="pinjam.kondisi"></b-select>
                </b-form-group>

                <b-button
                  variant="outline-dark"
                  :disabled="loading"
                  v-on:click="formPinjam = !formPinjam"
                >
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-primary" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-primary">
                    <i class="flaticon-share text-primary"></i>
                  </span>
                  Submit
                </b-button>
              </b-form>
            </b-col>
          </b-row>
          <b-row v-if="items.length">
            <div>
              <hr />
              <h5>Riwayat Peminjaman</h5>
            </div>
            <b-col md="12">
              <b-table
                head-variant="dark"
                bordered
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                stacked="sm"
                show-empty
                responsive
                :busy="isBusy"
              >
                <template #cell(tgl_pinjam)="data">
                  <span>{{ moment(data.item.tgl_pinjam) }}</span>
                </template>

                <template #cell(tgl_kembali)="data">
                  <span v-if="data.item.tgl_kembali">{{ moment(data.item.tgl_pinjam) }}</span>
                  <!--<b-form-input
                    v-else-if="
                      data.item.tgl_kembali == null && form.status == 2
                    "
                    id="tgl_kembali"
                    v-model="pinjam.tgl_kembali"
                    type="date"
                  ></b-form-input>-->
                </template>

                <template #cell(penggunaan)="data">
                  <span v-html="data.item.penggunaan"></span>
                </template>
                <template #cell(actions)="data">
                  <div v-if="data.item.tgl_kembali">
                    <h6 class="text-success" v-if="!role">
                      Telah dikembalikan
                    </h6>
                    <div v-else>
                      <h6 class="text-success" v-if="data.item.finish && data.item.tgl_kembali">
                        Telah dikembalikan
                      </h6>
                      <!--<div v-else>
                        <div class="btn btn-icon btn-clean btn-lg mr-1">
                          <span
                            class="svg-icon svg-icon-xl svg-icon-success"
                            v-b-tooltip.hover
                            title="Kondisi Baik"
                            @click="
                              onSubmitPengembalian(
                                data.item.id_peminjaman_peralatan_inspeksi,
                                1
                              )
                            "
                          >
                            <inline-svg src="media/svg/icons/Media/Back.svg" />
                          </span>
                        </div>
                        <div class="btn btn-icon btn-clean btn-lg mr-1">
                          <span
                            class="svg-icon svg-icon-xl svg-icon-danger"
                            v-b-tooltip.hover
                            title="Kondisi Rusak"
                            @click="
                              onSubmitPengembalian(
                                data.item.id_peminjaman_peralatan_inspeksi,
                                3
                              )
                            "
                          >
                            <inline-svg src="media/svg/icons/Media/Back.svg" />
                          </span>
                        </div>
                      </div>-->
                    </div>
                  </div>
                  <h6
                    v-else-if="data.item.tgl_kembali == null && form.status == 4 && !role"
                    class="text-warning"
                  >
                    Menuggu Approval
                  </h6>
                  <div v-else-if="data.item.tgl_kembali == null && form.status == 4 && role">
                    <div class="btn btn-icon btn-clean btn-lg mr-1">
                      <span
                        class="svg-icon svg-icon-xl svg-icon-success"
                        v-b-tooltip.hover
                        title="Setujui"
                        @click="onSubmitApproval(data.item.id_peminjaman_peralatan_inspeksi, 2)"
                      >
                        <i class="flaticon2-check-mark text-success"></i>
                      </span>
                    </div>
                    <div class="btn btn-icon btn-clean btn-lg mr-1">
                      <span
                        class="svg-icon svg-icon-xl svg-icon-danger"
                        v-b-tooltip.hover
                        title="Tolak"
                        @click="onSubmitApproval(data.item.id_peminjaman_peralatan_inspeksi, 6)"
                      >
                        <i class="flaticon-close text-dark"></i>
                      </span>
                    </div>
                  </div>
                  <div v-else-if="data.item.tgl_kembali == null && data.item.finish">
                    <h6 class="text-danger">Reject</h6>
                  </div>
                  <!--<div v-else>
                    <h6 v-if="!role" class="text-success">
                      <div class="btn btn-icon btn-clean btn-lg mr-1">
                        <span
                          class="svg-icon svg-icon-xl svg-icon-success"
                          v-b-tooltip.hover
                          title="Kondisi Baik"
                          @click="
                            onSubmitPengembalian(
                              data.item.id_peminjaman_peralatan_inspeksi,
                              5
                            )
                          "
                        >
                          <inline-svg src="media/svg/icons/Media/Back.svg" />
                        </span>
                      </div>
                    </h6>
                    <div v-else>
                      <div class="btn btn-icon btn-clean btn-lg mr-1">
                        <span
                          class="svg-icon svg-icon-xl svg-icon-success"
                          v-b-tooltip.hover
                          title="Kembalikan Dalam Kondisi Baik"
                          @click="
                            onSubmitPengembalian(
                              data.item.id_peminjaman_peralatan_inspeksi,
                              1
                            )
                          "
                        >
                          <inline-svg src="media/svg/icons/Media/Back.svg" />
                        </span>
                      </div>
                      <div class="btn btn-icon btn-clean btn-lg mr-1">
                        <span
                          class="svg-icon svg-icon-xl svg-icon-danger"
                          v-b-tooltip.hover
                          title="Kembalikan Dalam Kondisi Rusak"
                          @click="
                            onSubmitPengembalian(
                              data.item.id_peminjaman_peralatan_inspeksi,
                              3
                            )
                          "
                        >
                          <inline-svg src="media/svg/icons/Media/Back.svg" />
                        </span>
                      </div>
                    </div>
                  </div>-->
                </template>

                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col md="5">
              <b-pagination
                v-model="currentPage"
                @input="paginate"
                :total-rows="totalRows"
                :per-page="10"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
            <b-col md="5">
              <h4 class="float-right">
                Showing
                <span v-if="totalPerPage">1-{{ totalPerPage }}</span>
                <span v-else>0</span>

                of {{ totalRows }}
              </h4>
            </b-col>
          </b-row>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>
<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapState } from 'vuex'

import ApiService from '@/core/services/api.service'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import { VueEditor } from 'vue2-editor'
import $ from 'jquery'

import * as moment from 'moment'
import 'moment/locale/id'

export default {
  mixins: [validationMixin],
  name: 'addpabrik',
  data() {
    return {
      kondisi: [
        { text: 'Baik', value: 1 },
        { text: 'Rusak', value: 3 },
      ],
      form: {
        nama_peralatan: null,
        model: null,
        fungsi: null,
        tahun_dibuat: null,
        nomor_inventaris: null,
        foto: null,
        manual_book: null,
        manufacture: null,
        spesifikasi: null,
        kategori: null,
        tanggal_pembelian: null,
        link: null,
        instruksi_kerja: null,
        kuantity_peralatan: null,
        IsInvestasi: null,
        status: null,
      },
      formPinjam: false,
      pinjam: {
        tgl_pinjam: null,
        tgl_kembali: null,
        penggunaan: null,
        durasi: null,
        kondisi: null,
      },
      optionsKategori: [
        { value: null, text: '-- Pilih Kategori --' },
        { value: 'Ultrasonic Thickness', text: 'Ultrasonic Thickness' },
        { value: 'Ultrasonic Flaw Detector', text: 'Ultrasonic Flaw Detector' },
        { value: 'Ultrasonic Phased Array', text: 'Ultrasonic Phased Array' },
        { value: 'Thermograph', text: 'Thermograph' },
        { value: 'Eddy Current', text: 'Eddy Current' },
        { value: 'Borescope', text: 'Borescope' },
        { value: 'Magnetic Particle', text: 'Magnetic Particle' },
        { value: 'Hardness Tester', text: 'Hardness Tester' },
        { value: 'Lain-lain', text: 'Lain-lain' },
      ],
      items: [],
      fields: [
        { key: 'name', label: 'Peminjam' },
        { key: 'tgl_pinjam', label: 'Tanggal Pinjam' },
        { key: 'penggunaan', label: 'Penggunaan' },
        { key: 'durasi', label: 'Durasi (Jam)' },
        { key: 'tgl_kembali', label: 'Tanggal Kembali' },
        { key: 'actions', label: 'Actions' },
      ],
      totalRows: 0,
      totalPerPage: 0,
      currentPage: 1,
      isBusy: false,
      loading: false,

      role: false,
      id: 0,
    }
  },
  validations: {
    form: {
      nama_peralatan: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
    VueEditor,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Peralatan Inspeksi', route: '/peralataninspeksi' },
      { title: 'Edit' },
    ])
  },
  created() {
    this.id = this.$route.params.id
    this.loadData()
  },
  methods: {
    moment: function(date) {
      moment.locale('id')
      return moment(date, 'YYYY-MM-DD').format('dddd, LL')
    },
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.loadData()
    },
    paginate() {
      this.loadData()
    },
    async loadData() {
      var self = this
      self.isBusy = true

      await this.timeout(100)
      ApiService.get('/peralataninspeksi/edit/' + self.id + '?page=' + this.currentPage)
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.form.nama_peralatan = data.data.nama_peralatan
            self.form.model = data.data.model
            self.form.fungsi = data.data.fungsi
            self.form.tahun_dibuat = data.data.tahun_dibuat
            self.form.nomor_inventaris = data.data.nomor_inventaris
            self.form.manufacture = data.data.manufacture
            self.form.spesifikasi = data.data.spesifikasi
            self.form.kategori = data.data.kategori
            self.form.tanggal_pembelian = data.data.tanggal_pembelian
            self.form.link = data.data.link == 'null' ? '' : data.data.link
            self.form.instruksi_kerja = data.data.instruksi_kerja
            self.form.kuantity_peralatan = data.data.kuantity_peralatan
            self.form.IsInvestasi = data.data.IsInvestasi
            self.form.status = data.data.status

            self.role = data.role

            self.items = data.peminjaman.data
            self.totalRows = data.peminjaman.total
            self.totalPerPage = data.peminjaman.data.length
            self.isBusy = false
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      // event.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return
      }
      var self = this
      const nama_peralatan = self.$v.form.nama_peralatan.$model
      const model = self.form.model
      const fungsi = self.form.fungsi
      const tahun_dibuat = self.form.tahun_dibuat
      const nomor_inventaris = self.form.nomor_inventaris
      const foto = self.form.foto
      const manual_book = self.form.manual_book
      const manufacture = self.form.manufacture
      const spesifikasi = self.form.spesifikasi
      const kategori = self.form.kategori
      const tanggal_pembelian = self.form.tanggal_pembelian
      const link = self.form.link
      const instruksi_kerja = self.form.instruksi_kerja
      const kuantity_peralatan = self.form.kuantity_peralatan
      const IsInvestasi = self.form.IsInvestasi

      const dataku = new FormData()
      dataku.append('nama_peralatan', nama_peralatan)
      dataku.append('model', model)
      dataku.append('fungsi', fungsi)
      dataku.append('tahun_dibuat', tahun_dibuat)
      dataku.append('nomor_inventaris', nomor_inventaris)
      if (foto) {
        dataku.append('foto', foto)
      }
      if (manual_book) {
        dataku.append('manual_book', manual_book)
      }
      dataku.append('manufacture', manufacture)
      dataku.append('spesifikasi', spesifikasi)
      dataku.append('kategori', kategori)
      dataku.append('tanggal_pembelian', tanggal_pembelian)
      dataku.append('link', link)
      dataku.append('instruksi_kerja', instruksi_kerja)
      dataku.append('kuantity_peralatan', kuantity_peralatan)
      dataku.append('IsInvestasi', IsInvestasi)

      var urlApi = ApiService.urlApi()

      $.ajax({
        url: urlApi + '/peralataninspeksi/update/' + self.id,
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        headers: ApiService.headerAPi(),
        success: function(response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil diubah')
            self.$router.push('/peralataninspeksi')
          }
        },
      })
    },
    onSubmitPeminjaman() {
      const self = this
      self.isBusy = true
      self.loading = true
      const tgl_pinjam = self.pinjam.tgl_pinjam
      const penggunaan = self.pinjam.penggunaan
      const durasi = self.pinjam.durasi
      const kondisi = self.pinjam.kondisi

      const dataku = new FormData()
      dataku.append('tgl_kembali', tgl_pinjam)
      dataku.append('penggunaan', penggunaan)
      dataku.append('durasi', durasi)
      dataku.append('kondisi', kondisi)

      var urlApi = ApiService.urlApi()

      $.ajax({
        url: urlApi + '/peralataninspeksi/kembali/' + self.id,
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        headers: ApiService.headerAPi(),
        success: function(response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil dikembalikan')
            self.loadData()
            self.isBusy = false
            self.loading = false
            self.formPinjam = false
          }
        },
      })
    },
    onSubmitPengembalian(id, status) {
      const self = this
      // if (self.pinjam.tgl_kembali) {
      self.isBusy = true
      self.loading = true
      const tgl_kembali = self.pinjam.tgl_kembali

      const dataku = new FormData()
      dataku.append('tgl_kembali', tgl_kembali)
      dataku.append('status', status)

      var urlApi = ApiService.urlApi()

      $.ajax({
        url: urlApi + '/peralataninspeksi/kembali/' + id,
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        headers: ApiService.headerAPi(),
        success: function(response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil dikembalikan')
            self.loadData()
            self.isBusy = false
            self.loading = false
            self.formPinjam = false
          }
        },
      })
      // } else {
      //   self.makeToast("info", "Tanggal pengembalian harus terisi.");
      // }
    },
    onSubmitApproval(id, status) {
      const self = this
      self.isBusy = true
      self.loading = true

      var urlApi = ApiService.urlApi()
      const dataku = new FormData()
      dataku.append('status', status)

      $.ajax({
        url: urlApi + '/peralataninspeksi/approval/' + id,
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'post',
        headers: ApiService.headerAPi(),
        success: function(response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.loadData()
            self.isBusy = false
            self.loading = false
            self.formPinjam = false
          }
        },
      })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
  },
}
</script>
